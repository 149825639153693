import React, { useEffect, useState } from 'react'
import ProgressiveImage from 'react-progressive-graceful-image';
import axios from '../../config/api';
import { ArrowCircleLeftIcon, ArrowCircleRightIcon } from '@heroicons/react/solid';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

export default function Banner() {
    const [BannerData, setBannerData] = useState([])

    useEffect(() => {
        bannerhandler()
    }, [])

    const bannerhandler = async () => {
        const bannerRes = await axios.get(process.env.REACT_APP_SERVER_API + "/banner?type=Main", {
            headers: {
                authorization: 'Basic d2FsbGVtcGlyZTp3YWxsZW1waXJl'
            }
        })

        if (bannerRes.status === 200) {
            setBannerData([...bannerRes.data.data.sort((a, b) => a.order - b.order)])
        }
    }

    const properties = {
        prevArrow: <ArrowCircleLeftIcon
            className="h-7 md:h-10 absolute text-yellow-400 flex z-10 left-5 bg-white cursor-pointer rounded-full shadow-lg hover:shadow-none" />,
        nextArrow: <ArrowCircleRightIcon
            className="h-7 md:h-10 absolute text-yellow-400 flex z-10 right-5 bg-white cursor-pointer rounded-full shadow-lg hover:shadow-none" />
    }
    const indicators = () => (<div className="indicator cursor-pointer px-4 bg-gray-300 rounded-full text-center">&nbsp;</div>);

    return (
        <div>
            <Fade scale={1.4} indicators={indicators}  {...properties} autoplay duration={2000} infinite>
                {BannerData && BannerData.map((slideImage, index) => (
                    <ProgressiveImage src={slideImage?.image} placeholder={slideImage?.thumbnail} key={index}>
                        {(src) => (
                            <img
                                className="relative select-none flex items-center z-0 justify-center h-72 sm:h-72 md:h-full lg:h-screen 2xl:h-screen w-full"
                                src={src}
                                alt="Banner images"
                            />
                        )}
                    </ProgressiveImage>
                ))}
            </Fade>
        </div>
    )
}